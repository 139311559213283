import config from 'admin-panel/config/environment';

const timeoutDuration = (duration: number) =>
  config.environment === 'test' ? 0 : duration;

export const LAYOUT_SLIDE_OVER_ANIMATION_DURATION = timeoutDuration(300);
export const TEXT_FIELD_COPY_TASK_TIMEOUT = timeoutDuration(200);
export const LOAD_LIST_DEBOUNCE = timeoutDuration(250);
export const LAYOUT_MODAL_ANIMATION_DURATION = timeoutDuration(200);
export const HOTEL_CLONE_DEBOUNCE_TIMEOUT = timeoutDuration(250);
export const LIST_STATUS_LOADING_INDICATOR_TIMEOUT = timeoutDuration(500);
export const BUTTON_LOADING_INDICATOR_TIMEOUT = timeoutDuration(500);
export const LAYOUT_RESOURCE_EDITOR_ANIMATION_DURATION = timeoutDuration(100);
export const REMOTE_REFERENCES_FETCH_TIMEOUT = timeoutDuration(500);
